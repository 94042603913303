import * as React from 'react'

import { KeyboardKeys } from '@thg-commerce/gravity-theme'

import { Container, RadioTab, Tab, TabList, TabPanel, TabPrice } from './styles'

export interface TabProps {
  title?: string
  icon?: React.ReactNode
  displayPrice?: string
  content?: React.ReactNode
  id?: string
}

export type TabBorderStyle = 'SingleTab' | 'MultiTab'

export interface TabsProps {
  tabs: TabProps[]
  showInactiveBg?: boolean
  borderStyle?: TabBorderStyle
  tabIndex?: number // is used to display the initial tab
  setActiveTabIndex?: (activeTab: number) => void
  disableTabKey?: boolean
  alternateStyling?: boolean
  enableSubscribeAndSaveInlinePricing?: boolean
  disableSubscriptionButton?: boolean
}

export const Tabs = ({
  tabs,
  borderStyle,
  tabIndex,
  setActiveTabIndex,
  disableTabKey,
  alternateStyling,
  showInactiveBg,
  enableSubscribeAndSaveInlinePricing,
  disableSubscriptionButton,
}: TabsProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(tabIndex || 0)
  const [focusedTabIndex, setFocusedTabIndex] = React.useState(tabIndex || 0)

  const refs = tabs.map(() => React.createRef<HTMLButtonElement>())

  React.useEffect(() => {
    setSelectedTabIndex(tabIndex || 0)
    setFocusedTabIndex(tabIndex || 0)
  }, [tabIndex])

  const handleKeyBoard = (event: React.KeyboardEvent) => {
    let newTabIndex: number = selectedTabIndex

    switch (event.key) {
      case KeyboardKeys.ArrowRight:
        newTabIndex =
          selectedTabIndex === tabs.length - 1 ? 0 : selectedTabIndex + 1
        setSelectedTabIndex(newTabIndex)
        setFocusedTabIndex(newTabIndex)
        refs[newTabIndex].current?.focus()
        break

      case KeyboardKeys.ArrowLeft:
        newTabIndex =
          selectedTabIndex === 0 ? tabs.length - 1 : selectedTabIndex - 1
        setSelectedTabIndex(newTabIndex)
        setFocusedTabIndex(newTabIndex)
        refs[newTabIndex].current?.focus()
        break

      case KeyboardKeys.Tab:
        if (event.shiftKey && focusedTabIndex === 0) {
          setFocusedTabIndex(0)
        } else {
          setFocusedTabIndex(focusedTabIndex + 1)
        }
        newTabIndex = focusedTabIndex
        refs[newTabIndex]?.current?.focus()
        break
    }
  }

  const handleKeyBoardPanel = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab' && event.shiftKey) {
      refs[selectedTabIndex + 1]?.current?.focus()
      setFocusedTabIndex(selectedTabIndex)
    }
  }

  const tabButton = 'tab-button'
  const tabPanel = 'tab-panel'

  return (
    <Container>
      <TabList
        role="tablist"
        alternateStyling={alternateStyling}
        enableSubscribeAndSaveInlinePricing={
          enableSubscribeAndSaveInlinePricing
        }
      >
        {tabs.map((tab: TabProps, index: number) => {
          const TabComponent = enableSubscribeAndSaveInlinePricing
            ? RadioTab
            : Tab

          return (
            <TabComponent
              ref={refs[index]}
              key={`${tabButton}-${index}`}
              id={`${tabButton}-${index}`}
              tabIndex={index !== selectedTabIndex ? -1 : 0}
              aria-controls={`${tabPanel}-${index}`}
              role="tab"
              aria-selected={index === selectedTabIndex}
              isSelected={index === selectedTabIndex}
              onClick={() => {
                setSelectedTabIndex(index)
                setActiveTabIndex && setActiveTabIndex(index)
              }}
              onKeyDown={handleKeyBoard}
              borderStyle={borderStyle || 'SingleTab'}
              alternateStyling={alternateStyling}
              showInactiveBg={showInactiveBg}
              disabled={index === 1 && disableSubscriptionButton}
            >
              {enableSubscribeAndSaveInlinePricing && (
                <TabPrice>{tab.displayPrice}</TabPrice>
              )}
              {tab.title} {tab.icon}
            </TabComponent>
          )
        })}
      </TabList>

      <TabPanel
        role="tabpanel"
        tabIndex={disableTabKey ? -1 : 0}
        id={`${tabPanel}-${selectedTabIndex}`}
        aria-labelledby={`${tabButton}-${selectedTabIndex}`}
        onKeyDown={handleKeyBoardPanel}
        alternateStyling={alternateStyling}
      >
        {tabs[selectedTabIndex]?.content}
      </TabPanel>
    </Container>
  )
}
